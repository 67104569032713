export const PROFILE_LOAD = 'PROFILE_LOAD'

export const HABITS_ADD = 'HABITS_ADD'
export const HABITS_REMOVE = 'HABITS_REMOVE'
export const HABITS_NAME_EDIT = 'HABITS_NAME_EDIT'
export const HABITS_DATE_EDIT = 'HABITS_DATE_EDIT'
export const HABITS_PROGRESS_TOGGLE = 'HABITS_PROGRESS_TOGGLE'
export const HABITS_SCHEDULE_TOGGLE = 'HABITS_SCHEDULE_TOGGLE'

// -----------------------------------------------

export const ADMIN_USERS = 'ADMIN_USERS'
